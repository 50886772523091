<template>
  <div class="loading-placeholder">
    <div class="loading-placeholder__main">
      <div class="side-nav">
        <template v-for="(item, index) in [1,2,3,4,5,6,7]" :key="index">
          <div class="skeleton-box" :class="`side-nav__box-${item}`"></div>
        </template>
      </div>
      <div class="left-col skeleton-box"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.loading-placeholder {
  --loading-placeholder__skeleton-box--BackgroundColor: #0055bf33;

  position: fixed;
  z-index: var(--global__console-home--loading-placeholder--ZIndex);
  top: size(25);
  background: var(--global--primary--BackgroundColor);
  width: 100%;
  padding-left: var(--global__console-home--paddingLeft);
  padding-right: var(--global__console-home--paddingRight);
  animation: fadein .25s;
  height: 100vh;
  margin-top: size(70);

  & &__main {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: size(500);
    & .side-nav {
      flex: 1;
      max-width: size(135);
      margin-right: size(50);
      &__box {
        &-1, &-2, &-3, &-4, &-5, &-6 {
          width: size(80);
        }
      }
      & > div {
        height: size(30);
        margin-bottom: size(33);
        &:last-child {
          margin-bottom: unset;
        }
      }
    }
    & .left-col {
      flex: 10;
      height: 90%;
      margin-right: 10%;
      max-width: var(--global__console-home--Width);
    }
  }

  & .skeleton-box {
    display: inline-block;
    position: relative;
    overflow: hidden;
    background-color: var(--loading-placeholder__skeleton-box--BackgroundColor);
    border-radius: size(10);

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(#f8f6ff, 0) 0,
        rgba(#f8f6ff, 0.2) 20%,
        rgba(#f8f6ff, 0.5) 60%,
        rgba(#f8f6ff, 0)
      );
      animation: shimmer 1s infinite;
      content: '';
    }
  }
  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
</style>

<script>
export default {
  name: 'LoadingPlaceholder',
};
</script>
