<template>
  <div class="senior-menu" v-if="activeProject">
    <div class="logo"
    >
      <a href="/v2/" title="Home page" rel="noopener">
        <img class="logo__img" src="@/assets/img/rz-circle.svg" alt="roanuz sports logo" />
      </a>
    </div>
    <div  v-for="(route, prop, index) in routeListObj"
      :key="index" class="wrapper">
        <template v-if="activeProject.has_article_page_access">
          <router-link
          :to="{ name: route.routeName, params: { projectKey: activeProject.key } }"
          class="senior-menu__item"
          :class="route.routeName"
          >
            <div @click="reload()" class="inner-wrapper"
              :class="[{'active': $route.name === route.routeName} , route.routeName]">
              <svg class="senior-menu__item--icon" :class="route.routeName">
                <use v-bind="{ 'xlink:href': route.svgIcon }" />
              </svg>
            </div>
          </router-link>
          <span class="senior-menu__item--text"
            :class="route.routeName">{{route.displayName}}</span>
        </template>
        <template v-else-if="!activeProject.has_article_page_access && route.displayName !== 'Articles'">
          <router-link
          :to="{ name: route.routeName, params: { projectKey: activeProject.key } }"
          class="senior-menu__item"
          :class="route.routeName"
          >
            <div @click="reload()" class="inner-wrapper"
              :class="[{'active': $route.name === route.routeName} , route.routeName]">
              <svg class="senior-menu__item--icon" :class="route.routeName">
                <use v-bind="{ 'xlink:href': route.svgIcon }" />
              </svg>
            </div>
          </router-link>
          <span class="senior-menu__item--text"
            :class="route.routeName">{{route.displayName}}</span>
        </template>
    </div>
    <!-- <div class="wrapper">
      <a :href="loginGateway+'?next_url='+encodeURIComponent(falconLink)"
       target="_blank" class="senior-menu__item fantasyGame">
        <div class="inner-wrapper fantasyGame">
          <svg class="senior-menu__item--icon fantasyGame">
            <use v-bind="{ 'xlink:href': '#fantasy-game-icon' }" />
          </svg>
        </div>
      </a>
      <span class="senior-menu__item--text fantasyGame">Falcon</span>
    </div> -->
  </div>
</template>

<style lang="scss" scoped>
.senior-menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: var(--global__senior-menu--Width);
  text-align: center;
  padding-top: size(30);
  z-index: 10;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background: var(--global--common--Color);
  & &__item {
    &--text {
      display: inline;
      padding-left: size(12);
      color: var(--global--link--dark--Color);
    }
    margin-bottom: size(8);
  }
  .wrapper {
    position: relative;
    &.hide {
      .pushConfiguration {
        display: none;
      }
    }
    .inner-wrapper {
      border-radius: 50%;
      &.ProjectPage {
        padding: size(10);
        width: size(38);
        height: size(38);
        &.active,
        &:hover {
          background: var(--global--dashboard-bg--Color);
        }
      }
      &.usageReport {
        padding: size(9);
        width: size(38);
        height: size(38);
        &.active,
        &:hover {
          background: var(--global--usage-bg--Color);
        }
      }
      &.billing {
        padding: size(9);
        width: size(38);
        height: size(38);
        &.active,
        &:hover {
          background: var(--global--billing-bg--Color);
        }
      }
      &.pushConfiguration {
        padding: size(10) size(11);
        width: size(38);
        height: size(38);
        &.active,
        &:hover {
          background: var(--global--push-config-bg--Color);
        }
      }
      &.settings {
        padding: size(10);
        width: size(38);
        height: size(38);
        &.active,
        &:hover {
          background: var(--global--settings-bg--Color);
        }
      }
      &.circleji {
        padding: size(9);
        width: size(38);
        height: size(38);
        &.active,
        &:hover {
          background: #FFFDF1;
        }
      }
      &.fantasyGame,  &.articles {
        padding: size(8) size(9);
        width: size(38);
        height: size(38);
        &.active,
        &:hover {
          background: #FFE7E7;
        }
      }
    }
  }
  a{
    display: inline-block;
    border-radius: 50%;
    margin: 0 auto;
    transition: background-color 0.3s linear;
  }
  svg {
    display: inline-block;
    vertical-align: middle;
    &.ProjectPage {
      fill: var(--global--dashboard-img--Color);
      height: size(16);
      width: size(16);
    }
    &.usageReport {
      fill: var(--global--usage-img--Color);
      height: size(19);
      width: size(19);
    }
    &.billing {
      fill: var(--global--billing-img--Color);
      height: size(19);
      width: size(19);
    }
    &.pushConfiguration {
      fill: var(--global--push-config-img--Color);
      height: size(18);
      width: size(18);
    }
    &.settings {
      fill: var(--global--settings-img--Color);
      height: size(18);
      width: size(18);
    }
    &.circleji {
      height: size(20);
      width: size(20);
      border-radius: 50%;
    }
    &.fantasyGame,  &.articles {
      height: size(22);
      width: size(20);
    }
  }
  & &__item {
    &--text {
      display: block;
      font-size: size(11);
      line-height: size(15);
      color: var(--global--white--Color);
      left: 0;
      padding: 0;
      margin: 0 auto;
      max-width: size(45);
      margin-bottom: size(24);
      &.pushConfiguration {
        bottom: size(-30);
      }
    }
  }
  .logo {
    padding-bottom: size(30);
    a{
      padding: 0;
      img {
        width: size(38);
        height: size(38);
      }
    }
  }
}
</style>

<script>
import { mapState } from 'vuex';
import Config from '@/config';
import { Enum } from '@/core/utils/enum';

const routeNameList = new Enum({
  dashboard: 'ProjectPage',
  usageReport: 'usageReport',
  billing: 'billing',
  settings: 'settings',
  pushConfiguration: 'pushConfiguration',
  transactions: 'transactions',
  circleji: 'circleji',
  articles: 'articles',
  fantasyGame: 'fantasyGame',
});
export default {
  name: 'SeniorMenu',
  components: {},
  computed: {
    ...mapState({
      activeProject: (state) => state.project.activeProject,
    }),
  },
  data() {
    return {
      consoleLink: Config.consoleHost,
      loginGateway: Config.loginURL,
      falconLink: Config.falconURL,
      routeListObj: [
        {
          routeName: routeNameList.dashboard.value,
          displayName: 'Home',
          svgIcon: '#dashboard-icon',
        },
        {
          routeName: routeNameList.usageReport.value,
          displayName: 'Usage',
          svgIcon: '#usage-icon',
        },
        {
          routeName: routeNameList.billing.value,
          displayName: 'Billing',
          svgIcon: '#billing-icon',
        },
        {
          routeName: routeNameList.pushConfiguration.value,
          displayName: 'Push Config',
          svgIcon: '#configuration-icon',
        },
        {
          routeName: routeNameList.settings.value,
          displayName: 'Settings',
          svgIcon: '#settings-icon',
        },
        {
          routeName: routeNameList.articles.value,
          displayName: 'Articles',
          svgIcon: '#fantasy-game-icon',
        },
        // {
        //   routeName: routeNameList.circleji.value,
        //   displayName: 'Circle Ji Console',
        //   svgIcon: '#circleji-logo',
        // },
        // {
        //   routeName: routeNameList.fantasyGame.value,
        //   displayName: 'Fantasy Game',
        //   svgIcon: '#fantasy-game-icon',
        // },
      ],
      routeNameList,
      projectsWithFantasyAccess: [
        'RS_P_1527290301281079298',
        'RS_P_1537023265053216820',
        'RS_P_1537068689034186797',
        'RS_P_1537068447761043500',
      ],
    };
  },
  methods: {
    reload() {
      if (this.$route.query && this.$route.query === 'next-step') {
        window.location.reload();
      }
    },
  },
};
</script>
