<template>
  <div class="console-home">
    <SuperMenu
      class="console-home__super-menu"
    />
    <template
      v-if="activeProjectState.status.isInit || activeProjectState.status.isNotExist">
      <ConsoleOptions></ConsoleOptions>
      <Footer class="console-home__footer" />
    </template>
    <template v-else-if="activeProjectState.status.isLoaded">
      <div class="console-home__main-section">
        <SeniorMenu class="console-home__senior-menu" v-if="$route.name !== 'pagenotfound'"/>
        <div class="dynamic-content--wrapper">
          <router-view class="dynamic-content" :key="$route.fullPath"></router-view>
        </div>
      </div>
    </template>
    <template v-else-if="activeProjectState.status.isError">
      <PageNotFound
        :briefMesg="`This project is not associated with this login and
           try to access this project using the login associated with this project`"
        :errorTitle="'invalidProject'"
      />
    </template>
    <template v-else>
      <LoadingPlaceholder
        class="console-home__loading-placeholder"
      />
    </template>
    <all-svg></all-svg>
    <Footer class="console-home__footer"
      :class="[activeProjectState.status.isLoaded ? 'loaded': '']"/>
  </div>
</template>

<style lang="scss">
.console-home {
  min-height: 100vh;
  overflow: hidden;
  display: block;
  position: relative;
  padding-bottom: size(57);

  &__header {
    @include font-styles($type: 'display', $size: 'sm', $weight: 'bold');
    width: 100%;
    color: var(--global--main--title--Color);
    background: var(--global--primary--BackgroundColor);
    font-family: var(--global--primary--FontFamily);
    display: block;
    span {
      display: inline-block;
      max-width: size(700);
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .badge {
      @include font-styles($type: 'label', $size: 'xs', $weight: 'regular');
      padding: size(4) size(6);
      text-transform: uppercase;
      border-radius: size(2);
      margin-bottom: size(30);
      letter-spacing: 0.1em;
      height: size(22);
      &.standard {
        background: var(--global--standard-badge--BackgroundColor);
        width: size(90);
      }
      &.enterprise {
        background: var(--global--enterprise-badge--BackgroundColor);
        width: size(95);
      }
      &.package {
        width: size(82);
      }
      svg {
        width: size(9);
        height: size(9);
        display: inline-block;
        margin-right: size(5);
        margin-top: size(2);
        vertical-align: top;
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-left: 0;
    z-index: var(--global__console-home--super-menu--ZIndex);
    &.loaded {
      width: calc( 100vw - #{size(80)});
      margin-left: var(--global__senior-menu--Width);
    }
  }
  .btn-style-common {
    @include font-styles(
      $type: 'label',
      $size: 'sm',
      $weight: 'regular'
    );
    background: var(--global--common-link--Color);
    padding: size(10) size(18);
    color: var(--global--white--Color);
    border-radius: size(5);
    border: size(1) solid var(--global--common-link--Color);
    display: inline-block;
    &.outlined {
      background: none;
      border: size(1) solid var(--global--main--title--Color);
      color: var(--global--main--title--Color);
    }
    &.medium {
      @include font-styles(
        $type: 'label',
        $size: 'sm',
        $weight: 'regular'
      );
    }
    svg {
      width: size(10);
      height: size(10);
      fill: var(--global--white--Color);
      display: inline-block;
      margin-right: size(8);
      vertical-align: top;
      margin-top: size(2);
    }
  }
}
</style>

<style lang="scss" scoped>
.console-home {
  --console-home__dynamic-content--MaxWidth: #{size(1040)};

  margin: auto;
  position: relative;
  background: var(--global--primary--BackgroundColor);

  &__super-menu {
    z-index: var(--global__console-home--super-menu--ZIndex);
  }

  & {
    &.welcome-mesg {
      text-align: center;
    }
  }

  &__main-section {
    background: var(--global--primary--BackgroundColor);
    margin-left: var(--global__senior-menu--Width);
    animation: fadein .5s;
    .dynamic-content {
      padding-bottom: size(20);
      margin: size(80) auto size(20);
      min-width: size(1040);
      &--wrapper {
        max-width: var(--console-home__dynamic-content--MaxWidth);
        margin: 0 auto;
        .options-wrapper {
          text-align: center;
          padding-right: size(90);
          a.btn.focus:hover {
            color: var(--global--white--Color);
          }
        }
      }
    }
  }

  &__senior-menu {
    z-index: var(--global__console-home--senior-menu--ZIndex);
  }

  & > .console-home {
    &__footer,
    &__header,
    &__loading-placeholder,
    &__super-menu {
      padding-left: var(--global__console-home--paddingLeft);
      padding-right: var(--global__console-home--paddingRight);
    }
  }
}

</style>

<script>
import { mapState } from 'vuex';

import '@/assets/styles/global/styles.scss';
import AllSvg from '@/components/Svg/AllSvg.vue';
import { User } from '@/store/modules/user';
import { Project } from '@/store/modules/project';

import SuperMenu from '@/components/SuperMenu.vue';
import ConsoleOptions from '@/components/ConsoleOptions.vue';
import SeniorMenu from '@/components/SeniorMenu.vue';
import LoadingPlaceholder from '@/components/LoadingPlaceholder.vue';
import Footer from '@/components/Footer.vue';
import PageNotFound from './http404.vue';

export default {
  components: {
    AllSvg,
    SuperMenu,
    SeniorMenu,
    Footer,
    LoadingPlaceholder,
    PageNotFound,
    ConsoleOptions,
  },

  watch: {
    $route(to, from) {
      if (to !== from) {
        this.fetchBootData();
      }
      if (this.activeProject.key !== to.params.projectKey) {
        this.fetchData();
      }
      window.scrollTo(0, 0);
    },
  },

  data() {
    return {
      createProjectLink: Project.createProjectLink(),
    };
  },
  created() {
    return this.$store.dispatch('user/fetchActiveUser').then(() => {
      this.fetchBootData();
      this.fetchData();
    }).catch(() => {
      this.$router.push({ name: 'Login', query: { next_url: this.$route.fullPath } });
    });
  },
  computed: {
    ...mapState({
      activeProject: (state) => state.project.activeProject,
      activeProjectState: (state) => state.project.activeProjectState,
    }),
  },
  methods: {
    fetchData() {
      const { projectKey } = this.$route.params;
      if (projectKey) {
        this.$store.dispatch('project/fetchProject', projectKey).then(() => {
          this.fetchProjects();
        }).catch((err) => {
          console.log('selected project error', err);
        });
      } else {
        this.fetchProjects();
      }
    },
    fetchProjects() {
      this.$store.dispatch('project/fetchProjects').catch((err) => {
        console.log('Error on fetch projects', err);
      });
    },
    fetchBootData() {
      User.fetchBootApi().then((resp) => {
        if (localStorage.getItem('version')) {
          if (localStorage.getItem('version') !== resp.backend_version) {
            window.location.reload();
          }
        }
        if (resp) {
          localStorage.setItem('version', resp.backend_version);
        }
      });
    },
  },
};
</script>
