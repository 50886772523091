<template>
  <div class="super-menu" :class="[homeRoute ? '' : 'add-background']">
    <div class="logo"
    :class="[homeRoute ? 'hide' : 'show']">
      <a href="/v2/" title="Home page" rel="noopener">
        <img class="logo__img" src="@/assets/img/rz-circle.svg" alt="roanuz sports logo" />
        <span class="logo-title">Roanuz</span>
      </a>
    </div>
    <div class="dropdown-list">
      <router-link
        v-if="activeUser.haveStaffRights"
        class="dropdown-list__item"
        :to="{name: 'adminDashboard'}"
        :class="[homeRoute ? 'text-black' : 'text-white']"
      >
        <svg class="dropdown-list__item--icon" v-if="homeRoute">
          <use v-bind="{ 'xlink:href': '#help-icon' }" />
        </svg>
        <svg class="dropdown-list__item--icon" v-else>
          <use v-bind="{ 'xlink:href': '#help-icon-white' }" />
        </svg>
        <p class="dropdown-list__item--text"
        :class="[homeRoute ? 'text-black' : 'text-white']">Admin</p>
      </router-link>
      <div class="dropdown-list__item project" :class="[homeRoute ? 'text-black' : 'text-white']">
        <svg class="dropdown-list__item--icon"  v-if="homeRoute">
          <use v-bind="{ 'xlink:href': '#project-icon' }" />
        </svg>
        <svg class="dropdown-list__item--icon"  v-else>
          <use v-bind="{ 'xlink:href': '#project-icon-white' }" />
        </svg>
        <p class="dropdown-list__item--text">
          <span v-if="homeRoute" class="text-black" >{{activeProject.name}}</span>
          <span v-else class="text-white">Projects</span>
          <svg v-if="homeRoute">
            <use v-bind="{'xlink:href':'#project-dropdown'}"></use>
          </svg>
          <svg v-else>
            <use v-bind="{'xlink:href':'#dropdown-icon-white'}"></use>
          </svg>
        </p>
        <ProjectDropdown class="project-dropdown" />
      </div>
      <a
        class="dropdown-list__item"
        :href="docsLink"
        :class="[homeRoute ? 'text-black' : 'text-white']"
        title="docs page"
      >
        <svg class="dropdown-list__item--icon" v-if="homeRoute">
          <use v-bind="{ 'xlink:href': '#help-icon' }" />
        </svg>
        <svg class="dropdown-list__item--icon" v-else>
          <use v-bind="{ 'xlink:href': '#help-icon-white' }" />
        </svg>
        <p class="dropdown-list__item--text"
        :class="[homeRoute ? 'text-black' : 'text-white']">Docs</p>
      </a>
      <div class="dropdown-list__item user" >
        <p class="dropdown-list__item--text user-profile"
        v-if="activeUser.name">{{ activeUser.name.slice(0,1) }}</p>
        <UserDropdown class="user-dropdown" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.super-menu {

  --global__console-home--super-menu--PaddingTop: #{size(14)};
  --global__console-home--super-menu--PaddingBottom: #{size(14)};

  position: fixed;
  top: 0;
  left: size(80);
  right: 0;
  margin: auto;
  display: flex;
  justify-content: space-between;
  min-height: size(60);
  background: var(--global--primary--BackgroundColor);
  &.add-background {
    background: var(--global--common--Color);
    box-shadow: 0 size(3) size(10) rgba(0, 0, 0, 0.1);
    left: 0;
  }

  .hide {
    visibility: hidden;
  }
  .show {
    visibility: show;
  }
  .text-black {
    color: var(--global--main--title--Color);
  }
  .text-white {
    color: var(--global--white--Color);
  }
  .logo {
    align-self: center;
    .logo-title {
      font-family: var(--global--primary--FontFamily);
      display: inline-block;
      font-weight: bold;
      font-size: size(15);
      line-height: size(21);
      color: var(--global--white--Color);
      padding-left: size(10);
      vertical-align: top;
      padding-top: size(5);
    }
    &__image {
      height: size(40);
    }
  }
  .dropdown-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    &__item {
      position: relative;
      display: block;
      cursor: pointer;
      padding: var(--global__console-home--super-menu--PaddingTop) 0
        var(--global__console-home--super-menu--PaddingBottom) 0;
      &:not(:last-child) {
        margin-right: size(30);
      }
      &--icon {
        height: size(12);
        width: size(12);
      }
      &--text {
        display: inline-block;
        margin-left: size(10);
        @include font-styles($type: 'label', $size: 'lg', $weight: 'regular');
        text-transform: capitalize;
        svg {
          display: inline-block;
          width: size(8);
          height: size(4);
          margin-left: size(8);
          vertical-align: middle;
        }
        &.user-profile {
          @include font-styles($type: 'label', $size: 'sm', $weight: 'bold');
          margin-left: size(0);
          padding: size(5) size(10);
          background: var(--global--common-link--Color);
          border-radius: 50%;
          line-height: size(21);
          width: size(32);
          height: size(32);
          text-align: center;
          color: var(--global--white--Color);
        }
      }
      &.project {
        & .project-dropdown {
          display: none;
        }
      }
      &.user {
        &:after {
          border-bottom: size(0);
        }
        & .user-dropdown {
          display: none;
        }
      }
      &:hover .project-dropdown,
      &:hover .user-dropdown {
        display: block;
      }
      &:after {
        display: block;
        content: '';
        top: size(0);
        position: absolute;
        width: 100%;
        height: 100%;
        border-bottom: size(3) solid var(--global--common-link--Color);
        transform: scaleX(0);
        transition: transform 400ms ease-in-out;
      }
      &:hover:after {
        transform: scaleX(1);
      }
      &.text-white {
        &:after {
          top: size(4);
          border-bottom: size(3) solid var(--global--white--Color);
        }
      }
    }
  }
}
</style>

<script>
import { mapState } from 'vuex';
import Config from '@/config';
import ProjectDropdown from './ProjectDropdown.vue';
import UserDropdown from './UserDropdown.vue';

export default {
  name: 'SuperMenu',
  computed: {
    ...mapState({
      activeUser: (state) => state.user.activeUser,
      activeProject: (state) => state.project.activeProject,
      activeProjectState: (state) => state.project.activeProjectState,
    }),
    homeRoute() {
      if (this.$route.path !== '/v2/') {
        return true;
      }
      return false;
    },
  },
  components: { ProjectDropdown, UserDropdown },
  data() {
    return {
      docsLink: `${Config.cricketApiHost}/docs/v5/cricket/docs/`,
    };
  },
};
</script>
