<template>
  <div class="footer">
    <div>
      <div class="flex-col-left">
        <p>© Roanuz Softwares Private Limited</p>
      </div>
      <div class="flex-col-right">
        <template v-for="(linkItem, index) in linkItemList" :key="index">
          <a
            class="flex-col-right__link-item"
            :href="linkItem.url"
            target="_blank"
            :title="linkItem.text"
            rel=”noopener”
          >{{linkItem.text}}</a>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.footer {
  @include font-styles($type: 'label', $size: 'md', $weight: 'regular');
  padding-top: size(50);
  padding-bottom: size(20);
  margin-left: var(--global__senior-menu--Width);
  background: var(--global--primary--BackgroundColor);
  &.loaded {
    padding-right: 0!important;
    padding-left: 0 !important;
    .flex-col-left, .flex-col-right {
      padding-left: var(--global__console-home--paddingLeft);
      padding-right: var(--global__console-home--paddingRight);
    }
  }
  &> div {
    display: flex;
    justify-content: space-between;
  }
  & .flex-col {
    &-right {
      &__link-item {
        margin-right: size(30);
        color: var(--global--supportive--title--Color);
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
</style>

<script>
import Config from '@/config';

export default {
  name: 'Footer',
  components: {},
  data() {
    return {
      linkItemList: [
        {
          text: 'API terms of use.',
          url: `${Config.cricketApiHost}/legal/API-Terms-of-use/`,
        },
        {
          text: 'Terms & Privacy policy.',
          url: `${Config.cricketApiHost}/legal/Privacy-Policy/`,
        },
      ],
    };
  },
};
</script>
