<template>
  <div class="console-options">
    <h1 class="console-options__header">Welcome {{user.name }}</h1>
    <template v-if="projectList && !activeProjectState.status.isNotExist">
      <div class="options-wrapper legacy-console" v-if="user.show_v2_console">
        <a :href="legacyLink" v-if="user.show_v2_console" class="project-list" title="Leagacy Link">
          <span class="content-wrapper">
            <span class="title">Legacy Console</span>
            <span class="content">To manage package based apps</span>
          </span>
          <svg>
            <use v-bind="{'xlink:href':'#arrow-icon'}" />
          </svg>
        </a>
      </div>
      <div class="wrapper" v-if="projectList && projectList.length > 0">
        <div class="title-block" v-if="packageList && packageList.length > 0">
          <hr>
          <span>Package Based Projects</span>
          <hr>
        </div>
        <div class="options-wrapper" v-if="packageList && packageList.length > 0">
          <div class="project-list pay-go-projects" v-for="project in packageList" :key="project.key">
            <a
              :href="consoleLink+'project/'+project.key+'/'"
            >
              <span class="content-wrapper">
                <span class="title">{{project.name}}</span>
                <span class="content">{{project.project_license.common_name}}</span>
              </span>
              <svg>
                <use v-bind="{'xlink:href':'#arrow-icon'}" />
              </svg>
            </a>
          </div>
        </div>
        <div class="title-block" v-if="user.show_v2_console || (packageList && packageList.length > 0)">
          <hr>
          <span>Pay as you go Projects</span>
          <hr>
        </div>
        <div class="options-wrapper" v-if="paygoList && paygoList.length > 0">
          <div class="project-list pay-go-projects" v-for="project in paygoList" :key="project.key">
            <a
              :href="consoleLink+'project/'+project.key+'/'"
            >
              <span class="content-wrapper">
                <span class="title">{{project.name}}</span>
                <span class="content">{{project.project_license.common_name}}</span>
              </span>
              <svg>
                <use v-bind="{'xlink:href':'#arrow-icon'}" />
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div
        class="options-wrapper project-btn"
      >
        <a class="project-list create-project new-project"
          :href="sportsLink+'user/project/create'" title="Create Project">
          Create a new project
        </a>
      </div>
    </template>
    <div
      v-else-if="(!projectList || projectList === null
      || projectList.length < 0) && activeProjectState.status.isNotExist
      && !user.show_v2_console"
        class="options-wrapper project-btn"
      >
        <a class="project-list create-project new-project"
          :href="sportsLink+'user/project/create?page=createProject'" title="Create Project">
          Create a new project
        </a>
      </div>
    <div class="loader" v-else>
      <div class="skeleton-box"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Config from '@/config';

export default {
  name: 'Home',
  data() {
    return {
      projectList: null,
      paygoList: [],
      packageList: [],
      consoleLink: Config.consoleHost,
      sportsLink: Config.sportsHost,
      legacyLink: Config.legacyConsoleHost,
    };
  },
  computed: {
    ...mapState({
      activeProject: (state) => state.project.activeProject,
      activeProjectState: (state) => state.project.activeProjectState,
      user: (state) => state.user.activeUser,
    }),
  },
  created() {
    this.fetchProjects();
  },
  methods: {
    fetchProjects() {
      this.$store.dispatch('project/fetchProjects').then((resp) => {
        this.projectList = resp;
        for (let i = 0; i < this.projectList.length; i += 1) {
          if (this.projectList[i].project_license.product === 'rs_package') {
            this.packageList.push(this.projectList[i]);
          } else {
            this.paygoList.push(this.projectList[i]);
          }
        }
        this.redirect();
      }).catch((err) => {
        this.projectList = null;
        this.redirect();
        console.log('Error on fetch projects', err);
      });
    },
    redirect() {
      if ((this.projectList === null || (this.projectList && this.projectList.length === 0))
        && this.user.show_v2_console) {
        window.location = Config.legacyConsoleHost;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.console-options {
  text-align: center;
  background: var(--global--primary--BackgroundColor);
  padding-bottom: size(100);
  &__header {
    font-size: size(22);
    line-height: size(27);
    padding-bottom: size(20);
    padding-top: size(140);
    font-family: var(--global--primary--FontFamily);
    color: var(--global--main--title--Color);
    font-weight: bold;
  }
  .wrapper {
    max-width: size(380);
    margin: 0 auto;
  }
  .title-block {
    padding-bottom: size(14);
    hr {
      width: size(88);
      border: size(1) solid #DADADA;
      display: inline-block;
      vertical-align: bottom;
    }
    span {
      @include font-styles($type: 'label', $size: 'lg', $weight: 'bold');
      display: inline-block;
      color: var(--global--main--title--Color);
      padding: 0 size(10);
    }
  }
  .options-wrapper {
    box-shadow: 0 size(1) size(3) rgba(0, 0, 0, 0.1),
     0 size(2) size(1) rgba(0, 0, 0, 0.06), 0 size(1) size(1) rgba(0, 0, 0, 0.08);
    border-radius: size(8);
    background-color: var(--global--white--Color);
    max-width: size(380);
    border-radius: size(10);
    margin: 0 auto;
    margin-bottom: size(14);
    text-align: left;
    &.legacy-console {
      margin-bottom: size(30);
    }
    &.project-btn {
      background: none;
      box-shadow: none;
    }
    .project-list {
      padding: size(15);
      border-bottom: size(1) solid var(--global--line--table--BackgroundColor);
      cursor: pointer;
      display: block;
      color: var(--global--main--Color);
      .title {
        display: block;
        @include font-styles($type: 'label', $size: 'lg', $weight: 'bold');
        padding-bottom: size(6);
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--global--user--BackgroundColor);
      }
      .content{
        @include font-styles($type: 'label', $size: 'lg', $weight: 'regular');
      }
      svg {
        display: inline-block;
        vertical-align: top;
        margin-top: size(18);
        width: size(6);
        fill: var(--global--white--Color);
        stroke: var(--global--common-link--Color);
        height: size(12);
      }
      .content-wrapper {
        display: inline-block;
        width: 95%;
      }
      &.pay-go-projects {
        padding: 0;
        margin: 0 size(15);
        a {
          display: block;
          padding: size(15) 0;
          color: var(--global--main--Color);
        }
      }
      &.create-project {
        padding: size(22) size(30);
        .title {
          display: inline-block;
          width: 95%;
          padding-bottom: 0;
          color: var(--global--common-link--Color);
        }
        svg {
          margin-top: size(6);
          fill: var(--global--common-link--Color);
        }
        &.new-project {
          padding: size(13);
          width: 100%;
          color: var(--global--main--Color);
          border: size(1) solid var(--global--main--Color);
          border-radius: size(5);
          @include font-styles($type: 'label', $size: 'lg', $weight: 'bold');
          text-align: center;
        }
      }
    }
  }
}
.loader {
  --loader--box--BackgroundColor: #0055bf33;

  background: var(--loader--box--BackgroundColor);
  width: size(450);
  margin: 0 auto;
  animation: fadein .5s;
  border-radius: size(10);
  height: size(500);

  .skeleton-box {
    display: inline-block;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: var(--loader--box--BackgroundColor);
    border-radius: size(10);

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(#f8f6ff, 0) 0,
        rgba(#f8f6ff, 0.2) 20%,
        rgba(#f8f6ff, 0.5) 60%,
        rgba(#f8f6ff, 0)
      );
      animation: shimmer 1s infinite;
      content: '';
    }
  }
  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
</style>
