<template>
  <div class="error-page">
    <div class="error-page__wrapper">
      <h3 class="error-page__title">{{ titleMesg }}</h3>
      <p
        class="error-page__info"
      >{{ briefMesg }}</p>
      <div class="error-page__btn-group">
        <a :href="activeProject.setUserLogin" title="Login" class="btn-style-common medium">Login</a>
        <a href="/" class="btn primary-inv btn-sm" v-if="errorTitle === 'invalidProject'">skip</a>
        <a href="/v2/" class="btn primary-inv btn-sm" v-else>skip</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'PageNotFound',
  props: {
    titleMesg: {
      type: String,
      default: 'OH NOO!',
    },
    briefMesg: {
      type: String,
      default: 'You do not have access to explore this page, kindly sign-in with the respective credentials to explore',
    },
    errorTitle: {
      type: String,
      default: 'invalidUrl',
    },
  },
  computed: {
    ...mapState({
      activeProject: (state) => state.project.activeProject,
    }),
  },
};
</script>

<style lang="scss">
.console-home {
  .dynamic-content--wrapper {
    .error-page.dynamic-content {
      margin-top: size(100);
    }
  }
}
</style>

<style lang="scss" scoped>
.error-page {
  background: var(--global--primary--BackgroundColor);
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--global__console-home--senior-menu--ZIndex);
  margin-top: size(150);
  animation: render 0s .2s forwards;
  visibility: hidden;
  &__wrapper {
    padding: size(100) 0;
    margin-left: auto;
    margin-right: auto;
    background: var(--global--white--Color);
    width: 80%;
    max-width: size(1130);
  }
  &__title {
    max-width: size(645);
    padding-bottom: size(20);
    text-align: center;
    margin: auto;
    @include font-styles($type: 'display', $size: 'md', $weight: 'semibold');
    color: var(--global--main--title--Color);
  }
  &__info {
    max-width: size(600);
    padding-bottom: size(30);
    text-align: center;
    margin: auto;
  }
  &__btn-group {
    text-align: center;
  }
  btn {
    text-align: center;
  }
  .super-menu {
    z-index: var(--global__console-home--super-menu--ZIndex);
    padding-left: var(--global__console-home--paddingLeft);
    padding-right: var(--global__console-home--paddingRight);
  }
}

@keyframes render {
  to   { visibility: visible; }
}
</style>
