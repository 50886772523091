<template>
  <div class="project-dropdown">
    <div class="header">
      <div class="header__label">
        <svg class="header__label--icon">
          <use v-bind="{ 'xlink:href': '#project-icon' }" />
        </svg>
        <p class="header__label--text">Projects</p>
      </div>
      <a :href="createProjectLink" title="Create new project" target="_blank" class="btn-style-common medium">
        <svg>
          <use v-bind="{ 'xlink:href': '#plus-icon' }" />
        </svg>
        <span>New Project</span>
      </a>
    </div>
    <div class="project-dropdown__table" v-if="projectList">
      <h3 class="project-dropdown__table--title">Your Recent Projects</h3>
      <div class="wrapper">
        <template
          v-for="(project, index) in projectList" :key="index">
          <template v-if="project.hasValidLicense">
            <router-link
              class="table-row"
              :to="{ name: 'ProjectPage', params: { projectKey: project.key }}"
            >
              <div class="table-column__project-name" :class="{'active': !index}">
                <p>{{project.name}}</p>
              </div>
              <div
                class="table-column__customer-type"
                :class="{'enterprise':project.project_license.license_key.isEnterprise ||
                project.project_license.license_key.isEnterpriseC1x }"
              >
                <span
                  v-if="project.project_license.license_key.isEnterprise ||
                  project.project_license.license_key.isEnterpriseC1x"
                >
                  <svg>
                    <use v-bind="{ 'xlink:href': '#enterprise-icon' }" />
                  </svg>
                </span>
                <p>
                  {{ project.project_license.common_name }}
                </p>
              </div>
              <div class="table-column__payment-status" :class="[project.project_payment_status.value]">
                <span>
                  <svg>
                    <use v-bind="{ 'xlink:href': '#tickmark-icon' }"
                    v-if="project.project_payment_status.isPaid
                    || project.project_payment_status.isBillingVerified"/>
                    <use v-bind="{ 'xlink:href': '#clock-icon' }" v-else />
                  </svg>
                </span>
                <p>
                  <span>
                    {{project.project_payment_status.label}}</span>
                </p>
              </div>
            </router-link>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.project-dropdown {
  --project-dropdown--Top: #{size(50)};
  --project-dropdown--Right: #{size(-190)};
  --project-dropdown--Width: #{size(800)};
  --project-dropdown--Padding: #{size(40)};
  --project-dropdown--ZIndex: 11;
  --project-dropdown__header--PaddingBottom: #{size(30)};
  --project-dropdown__table-title--PaddingBottom: #{size(20)};
  --project-dropdown__project-name--Width: 42%;
  --project-dropdown__project-name--PaddingLeft: #{size(0)};
  --project-dropdown__customer-type--Width: 35%;
  --project-dropdown__customer-type--PaddingLeft: #{size(20)};
  --project-dropdown__payment-status--Width: 15%;
  --project-dropdown__payment-status--PaddingLeft: #{size(20)};
  --project-dropdown__table-row--PaddingLeft: #{size(10)};

  position: absolute;
  top: var(--project-dropdown--Top);
  right: var(--project-dropdown--Right);
  min-width: var(--project-dropdown--Width);
  display: flex;
  flex-direction: column;
  padding: var(--project-dropdown--Padding);
  background: var(--global--white--Color);
  cursor: alias;
  z-index: var(--project-dropdown--ZIndex);
  box-shadow: 0 size(1) size(5) rgba(0, 0, 0, 0.15);
  transition: max-height 0.55s ease-in;
  animation: fadein 0.2s ease-in;

  & .header {
    display: flex;
    justify-content: space-between;
    padding-bottom: var(--project-dropdown__header--PaddingBottom);
    &__label {
      &--icon {
        fill: var(--global--main--title--Color);
        height: size(26);
        width: size(30);
        vertical-align: bottom;
      }
      &--text {
        @include font-styles(
          $type: 'display',
          $size: 'xs',
          $weight: 'semibold'
        );
        display: inline-block;
        margin-left: size(10);
        color: var(--global--main--title--Color);
      }
    }
    &__btn {
      margin: auto 0;
      svg {
        height: size(14);
        width: size(14);
        margin-right: size(10);
        fill: var(--global--white--Color);
      }
    }
  }
  &__table {
    display: flex;
    flex-direction: column;
    color: var(--global--main--Color);
    max-height: size(430);
    overflow: hidden;
    &--title {
      @include font-styles($type: 'text', $size: 'lg', $weight: 'semibold');
      padding-bottom: var(--project-dropdown__table-title--PaddingBottom);
    }
    .wrapper {
      overflow-y: auto;
    }
    & .table-row {
      display: flex;
      flex-direction: row;
      @include font-styles($type: 'label', $size: 'lg', $weight: 'regular');
      color: var(--global--main--Color);
      padding: var(--project-dropdown__table-row--PaddingLeft) size(0);
      border-bottom: size(1) solid var(--global--line--table--BackgroundColor);
      text-align: left;
      outline: none;
      cursor: pointer;
      &:first-of-type {
        cursor: alias;
      }
      &:last-child {
        border-bottom: none;
      }
      & p {
        display: inline-block;
        max-width: size(300);
        overflow: hidden;
        text-overflow: ellipsis;
      }
      & svg {
        margin-right: size(10);
      }
      .table-column {
        &__project-name {
          min-width: var(--project-dropdown__project-name--Width);
          padding-left: var(--project-dropdown__project-name--PaddingLeft);
          &.active p {
            color: var(--global--common-link--Color);
          }
        }
        &__customer-type {
          min-width: var(--project-dropdown__customer-type--Width);
          padding-left: var(--project-dropdown__customer-type--PaddingLeft);
          display: inline-block;
          &.enterprise {
            color: var(--global--premium--Color);
            display: inline-block;
            vertical-align: top;
            span{
              display: inline-block;
              vertical-align: top;
            }
            svg {
              display: inline-block;
              height: size(11);
              width: size(14);
              fill: var(--global--premium--Color);
            }
          }
        }
        &__payment-status {
          min-width: var(--project-dropdown__payment-status--Width);
          padding-left: var(--project-dropdown__payment-status--PaddingLeft);
          span {
            text-transform: capitalize;
            display: inline-block;
            vertical-align: top;
          }
          &.paid, &.billing_verified {
            svg {
              fill: var(--global--success--BackgroundColor);
            }
          }
          &.unpaid, &.unknown {
            svg {
              fill: var(--global--error--BackgroundColor);
            }
            p {
              color: var(--global--error--BackgroundColor);
            }
          }
          svg {
            height: size(11);
            width: size(11);
          }
        }
      }
      &:hover .table-column {
        &__project-name {
          p {
            color: var(--global--common-link--Color);
          }
        }
      }
    }
  }
}
</style>

<script>
import { mapState } from 'vuex';
import { Project } from '@/store/modules/project';

export default {
  name: 'projectDropdown',
  computed: {
    ...mapState({
      activeUser: (state) => state.user.activeUser,
      activeUserState: (state) => state.user.activeUserState,
      projectList: (state) => state.project.projectList.projects,
    }),
  },
  data() {
    return {
      createProjectLink: Project.createProjectLink(),
    };
  },
  methods: {
    gotToProject(projectKey) {
      this.$router.push({ name: 'ProjectPage', params: { projectKey } });
    },
  },
};
</script>
