<template>
  <div class="user-dropdown">
    <h3>{{activeUser.name}}</h3>
    <a class="user-dropdown__name" :href="'/project/'+ activeProject.key +'/profile'">
      Profile
    </a>
    <p
      class="user-dropdown__link"
    >
    <button class="user-dropdown__link--text" @click="signOut()">Sign out</button>
    </p>
  </div>
</template>

<style lang="scss" scoped>
.user-dropdown {
  position: absolute;
  top: size(55);
  right: size(0);
  padding: size(15);
  min-width: max-content;
  background: var(--global--white--Color);
  cursor: alias;
  box-shadow: 0 size(1) size(5) rgba(0, 0, 0, 0.15);
  transition: max-height 0.55s ease-in;
  animation: fadein 0.2s ease-in;
  min-width: size(137);

  h3 {
    @include font-styles($type: 'label', $size: 'sm', $weight: 'semibold');
    text-transform: uppercase;
    color: #777777;
    padding-bottom: size(15);
  }

  &__name {
    margin-bottom: size(10);
    text-align: left;
    color: var(--global--main--title--Color);
    display: block;
    @include font-styles($type: 'label', $size: 'lg', $weight: 'regular');
    padding-bottom: size(8);
    margin: 0;
    &:hover {
      animation: jump-up-shadow 350ms ease-out;
      color: var(--global--common-link--Color);
    }
  }

  &__link {
    display: block;
    color: var(--global--main--title--Color);
    @include font-styles($type: 'label', $size: 'lg', $weight: 'regular');
    margin-bottom: size(5);
    color: var(--global--main--title--Color);
    text-align: left;
    &--text {
      cursor: pointer;
      outline: none;
    }
    &:hover {
      animation: jump-up-shadow 350ms ease-out;
      color: var(--global--common-link--Color);
    }
    svg {
      fill: var(--global--main--title--Color);
      stroke: var(--global--main--title--Color);
    }
  }

  svg {
    height: size(11);
    width: size(10);
  }
}
</style>

<script>
import { mapState } from 'vuex';
import Config from '@/config';
import { User } from '@/store/modules/user';

export default {
  name: 'UserDropdown',
  data() {
    return {
      logoutLink: `${Config.sportsHost}user/logout/`,
    };
  },
  computed: {
    ...mapState({
      activeUser: (state) => state.user.activeUser,
      activeProject: (state) => state.project.activeProject,
    }),
  },
  methods: {
    signOut() {
      User.signOut()
        .then((resp) => {
          console.log('signout dropdown', resp);
          this.$router.push({
            name: 'Login',
          });
        }).catch((err) => {
          console.log('Unhandled error during signout', err);
          this.$router.push({
            name: 'Login',
          });
        });
    },
  },
};
</script>
